import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a6096136 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _77439a30 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _7941c71a = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _c31f38b2 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _5808b074 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3944e6c2 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _800f6ce6 = () => interopDefault(import('../pages/payment-methods.vue' /* webpackChunkName: "pages/payment-methods" */))
const _385a1d51 = () => interopDefault(import('../pages/dental-problems.vue' /* webpackChunkName: "pages/dental-problems" */))
const _20727848 = () => interopDefault(import('../pages/quality-guarantee.vue' /* webpackChunkName: "pages/quality-guarantee" */))
const _dbdddc66 = () => interopDefault(import('../pages/appointment-booking.vue' /* webpackChunkName: "pages/appointment-booking" */))
const _386f020a = () => interopDefault(import('../pages/implant-oral-surgery.vue' /* webpackChunkName: "pages/implant-oral-surgery" */))
const _1d93cb99 = () => interopDefault(import('../pages/invisalign-orthodontic-treatment.vue' /* webpackChunkName: "pages/invisalign-orthodontic-treatment" */))
const _cb617f8c = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _7d06488c = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _69eefa42 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _54cc3776 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _720e6b73 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _6b9469b8 = () => interopDefault(import('../pages/works.vue' /* webpackChunkName: "pages/works" */))
const _4626372f = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _1ab07138 = () => interopDefault(import('../pages/premium-dental-care.vue' /* webpackChunkName: "pages/premium-dental-care" */))
const _262ba144 = () => interopDefault(import('../pages/account/referral-program.vue' /* webpackChunkName: "pages/account/referral-program" */))
const _a5bfb4da = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _d38e34b8 = () => interopDefault(import('../pages/account/appointments.vue' /* webpackChunkName: "pages/account/appointments" */))
const _184a8458 = () => interopDefault(import('../pages/account/dashboard.vue' /* webpackChunkName: "pages/account/dashboard" */))
const _d3ddebac = () => interopDefault(import('../pages/about/introduction.vue' /* webpackChunkName: "pages/about/introduction" */))
const _4e2874de = () => interopDefault(import('../pages/about/career/index.vue' /* webpackChunkName: "pages/about/career/index" */))
const _98172188 = () => interopDefault(import('../pages/about/trainings/index.vue' /* webpackChunkName: "pages/about/trainings/index" */))
const _15b08543 = () => interopDefault(import('../pages/about/dentists/index.vue' /* webpackChunkName: "pages/about/dentists/index" */))
const _abae7678 = () => interopDefault(import('../pages/about/testimonials.vue' /* webpackChunkName: "pages/about/testimonials" */))
const _4a74bfff = () => interopDefault(import('../pages/about/career/preview/_job_preview.vue' /* webpackChunkName: "pages/about/career/preview/_job_preview" */))
const _728863b6 = () => interopDefault(import('../pages/about/trainings/preview/_training_preview.vue' /* webpackChunkName: "pages/about/trainings/preview/_training_preview" */))
const _0572aae2 = () => interopDefault(import('../pages/about/career/_job.vue' /* webpackChunkName: "pages/about/career/_job" */))
const _bc0659fa = () => interopDefault(import('../pages/about/dentists/_dentist.vue' /* webpackChunkName: "pages/about/dentists/_dentist" */))
const _2b91c383 = () => interopDefault(import('../pages/about/notifications/_notification.vue' /* webpackChunkName: "pages/about/notifications/_notification" */))
const _76b2bc23 = () => interopDefault(import('../pages/about/trainings/_training.vue' /* webpackChunkName: "pages/about/trainings/_training" */))
const _0786d5d4 = () => interopDefault(import('../pages/blog/preview/_article_preview.vue' /* webpackChunkName: "pages/blog/preview/_article_preview" */))
const _18640d6c = () => interopDefault(import('../pages/about/_content.vue' /* webpackChunkName: "pages/about/_content" */))
const _d7cf5fb4 = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _4df81c28 = () => interopDefault(import('../pages/services/_service/index.vue' /* webpackChunkName: "pages/services/_service/index" */))
const _461eb2a0 = () => interopDefault(import('../pages/services/_service/_price.vue' /* webpackChunkName: "pages/services/_service/_price" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adatvedelmi-nyilatkozat",
    component: _a6096136,
    name: "privacy-policy___hu"
  }, {
    path: "/araink",
    component: _77439a30,
    name: "pricing___hu"
  }, {
    path: "/blog",
    component: _7941c71a,
    name: "blog___hu"
  }, {
    path: "/elfelejtett-jelszo",
    component: _c31f38b2,
    name: "forgot-password___hu"
  }, {
    path: "/en",
    component: _5808b074,
    name: "index___en"
  }, {
    path: "/felhasznalasi-feltetelek",
    component: _3944e6c2,
    name: "terms-and-conditions___hu"
  }, {
    path: "/fizetesi-lehetosegek",
    component: _800f6ce6,
    name: "payment-methods___hu"
  }, {
    path: "/fogaszati-panaszok",
    component: _385a1d51,
    name: "dental-problems___hu"
  }, {
    path: "/garancia",
    component: _20727848,
    name: "quality-guarantee___hu"
  }, {
    path: "/idopontfoglalas",
    component: _dbdddc66,
    name: "appointment-booking___hu"
  }, {
    path: "/implantatum-szajsebeszet",
    component: _386f020a,
    name: "implant-oral-surgery___hu"
  }, {
    path: "/invisalign-fogszabalyozas",
    component: _1d93cb99,
    name: "invisalign-orthodontic-treatment___hu"
  }, {
    path: "/jelszovaltoztatas",
    component: _cb617f8c,
    name: "change-password___hu"
  }, {
    path: "/jogi-nyilatkozat",
    component: _7d06488c,
    name: "legal-notice___hu"
  }, {
    path: "/kapcsolat",
    component: _69eefa42,
    name: "contact___hu"
  }, {
    path: "/kereses",
    component: _54cc3776,
    name: "search___hu"
  }, {
    path: "/leiratkozas",
    component: _720e6b73,
    name: "unsubscribe___hu"
  }, {
    path: "/munkaink",
    component: _6b9469b8,
    name: "works___hu"
  }, {
    path: "/szolgaltatasok",
    component: _4626372f,
    name: "services___hu"
  }, {
    path: "/en/appointment-booking",
    component: _dbdddc66,
    name: "appointment-booking___en"
  }, {
    path: "/en/blog",
    component: _7941c71a,
    name: "blog___en"
  }, {
    path: "/en/change-password",
    component: _cb617f8c,
    name: "change-password___en"
  }, {
    path: "/en/contact",
    component: _69eefa42,
    name: "contact___en"
  }, {
    path: "/en/dental-problems",
    component: _385a1d51,
    name: "dental-problems___en"
  }, {
    path: "/en/forgot-password",
    component: _c31f38b2,
    name: "forgot-password___en"
  }, {
    path: "/en/invisalign-orthodontic-treatment",
    component: _1d93cb99,
    name: "invisalign-orthodontic-treatment___en"
  }, {
    path: "/en/legal-notice",
    component: _7d06488c,
    name: "legal-notice___en"
  }, {
    path: "/en/payment-methods",
    component: _800f6ce6,
    name: "payment-methods___en"
  }, {
    path: "/en/premium-dental-care",
    component: _1ab07138,
    name: "premium-dental-care___en"
  }, {
    path: "/en/pricing",
    component: _77439a30,
    name: "pricing___en"
  }, {
    path: "/en/privacy-policy",
    component: _a6096136,
    name: "privacy-policy___en"
  }, {
    path: "/en/quality-guarantee",
    component: _20727848,
    name: "quality-guarantee___en"
  }, {
    path: "/en/search",
    component: _54cc3776,
    name: "search___en"
  }, {
    path: "/en/services",
    component: _4626372f,
    name: "services___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _3944e6c2,
    name: "terms-and-conditions___en"
  }, {
    path: "/en/unsubscribe",
    component: _720e6b73,
    name: "unsubscribe___en"
  }, {
    path: "/en/works",
    component: _6b9469b8,
    name: "works___en"
  }, {
    path: "/profilom/ajalnasok",
    component: _262ba144,
    name: "account-referral-program___hu"
  }, {
    path: "/profilom/beallitasok",
    component: _a5bfb4da,
    name: "account-settings___hu"
  }, {
    path: "/profilom/idopontjaim",
    component: _d38e34b8,
    name: "account-appointments___hu"
  }, {
    path: "/profilom/vezerlopult",
    component: _184a8458,
    name: "account-dashboard___hu"
  }, {
    path: "/rolunk/bemutatkozas",
    component: _d3ddebac,
    name: "about-introduction___hu"
  }, {
    path: "/rolunk/karrier",
    component: _4e2874de,
    name: "about-career___hu"
  }, {
    path: "/rolunk/kepzeseink",
    component: _98172188,
    name: "about-trainings___hu"
  }, {
    path: "/rolunk/orvosaink",
    component: _15b08543,
    name: "about-dentists___hu"
  }, {
    path: "/rolunk/velemenyek",
    component: _abae7678,
    name: "about-testimonials___hu"
  }, {
    path: "/en/about/career",
    component: _4e2874de,
    name: "about-career___en"
  }, {
    path: "/en/about/dentists",
    component: _15b08543,
    name: "about-dentists___en"
  }, {
    path: "/en/about/introduction",
    component: _d3ddebac,
    name: "about-introduction___en"
  }, {
    path: "/en/about/testimonials",
    component: _abae7678,
    name: "about-testimonials___en"
  }, {
    path: "/en/about/trainings",
    component: _98172188,
    name: "about-trainings___en"
  }, {
    path: "/en/account/appointments",
    component: _d38e34b8,
    name: "account-appointments___en"
  }, {
    path: "/en/account/dashboard",
    component: _184a8458,
    name: "account-dashboard___en"
  }, {
    path: "/en/account/referral-program",
    component: _262ba144,
    name: "account-referral-program___en"
  }, {
    path: "/en/account/settings",
    component: _a5bfb4da,
    name: "account-settings___en"
  }, {
    path: "/en/about/career/preview/:job_preview",
    component: _4a74bfff,
    name: "about-career-preview-job_preview___en"
  }, {
    path: "/en/about/trainings/preview/:training_preview",
    component: _728863b6,
    name: "about-trainings-preview-training_preview___en"
  }, {
    path: "/en/about/career/:job",
    component: _0572aae2,
    name: "about-career-job___en"
  }, {
    path: "/en/about/dentists/:dentist",
    component: _bc0659fa,
    name: "about-dentists-dentist___en"
  }, {
    path: "/en/about/notifications/:notification",
    component: _2b91c383,
    name: "about-notifications-notification___en"
  }, {
    path: "/en/about/trainings/:training",
    component: _76b2bc23,
    name: "about-trainings-training___en"
  }, {
    path: "/en/blog/preview/:article_preview",
    component: _0786d5d4,
    name: "blog-preview-article_preview___en"
  }, {
    path: "/rolunk/karrier/preview/:job_preview",
    component: _4a74bfff,
    name: "about-career-preview-job_preview___hu"
  }, {
    path: "/rolunk/kepzeseink/preview/:training_preview",
    component: _728863b6,
    name: "about-trainings-preview-training_preview___hu"
  }, {
    path: "/blog/preview/:article_preview",
    component: _0786d5d4,
    name: "blog-preview-article_preview___hu"
  }, {
    path: "/en/about/:content",
    component: _18640d6c,
    name: "about-content___en"
  }, {
    path: "/en/blog/:article",
    component: _d7cf5fb4,
    name: "blog-article___en"
  }, {
    path: "/en/services/:service",
    component: _4df81c28,
    name: "services-service___en"
  }, {
    path: "/rolunk/ertesitesek/:notification",
    component: _2b91c383,
    name: "about-notifications-notification___hu"
  }, {
    path: "/rolunk/karrier/:job",
    component: _0572aae2,
    name: "about-career-job___hu"
  }, {
    path: "/rolunk/kepzeseink/:training",
    component: _76b2bc23,
    name: "about-trainings-training___hu"
  }, {
    path: "/rolunk/orvosaink/:dentist",
    component: _bc0659fa,
    name: "about-dentists-dentist___hu"
  }, {
    path: "/en/services/:service/:price",
    component: _461eb2a0,
    name: "services-service-price___en"
  }, {
    path: "/blog/:article",
    component: _d7cf5fb4,
    name: "blog-article___hu"
  }, {
    path: "/rolunk/:content",
    component: _18640d6c,
    name: "about-content___hu"
  }, {
    path: "/szolgaltatasok/:service",
    component: _4df81c28,
    name: "services-service___hu"
  }, {
    path: "/szolgaltatasok/:service/:price",
    component: _461eb2a0,
    name: "services-service-price___hu"
  }, {
    path: "/",
    component: _5808b074,
    name: "index___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
